import React from "react";
import "./MailChatExample.scss";

export default function MailChatExample() {
  return (
    <div className="comparison-container">
      <div className="comparison-box">
        <div className="comparison-header">
          <h3 className="comparison-title">Before</h3>
        </div>
        <div className="comparison-content">
          <span>전에 부탁드린거 언제되나요? 빨리 해주세요. </span>
        </div>
      </div>

      <div className="arrow-container">
        <svg
          className="arrow-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M5 12h14" />
          <path d="m12 5 7 7-7 7" />
        </svg>
      </div>

      <div className="comparison-box">
        <div className="comparison-header">
          <h3 className="comparison-title">After</h3>
        </div>
        <div className="comparison-content">
          <span>
            전에 부탁드린 내용이 언제 처리될 수 있을지 궁금합니다. <br></br>
            가능한 한 빨리 진행해 주시면 감사하겠습니다.
          </span>
        </div>
      </div>
    </div>
  );
}
