import React from "react";
import "./ChatGuide.scss";

const ChatGuide = () => {
  return (
    <div className="chat-guide">
      <ul>
        <li>본 챗봇은 사규에 대한 질문에 답변할 수 있습니다.</li>
        <li>
          <strong>중요:</strong> 챗봇이 잘못된 정보를 제공할 수 있으니, 중요한
          결정 시 반드시 실제 사규 문서를 확인하시기 바랍니다.
        </li>
      </ul>
    </div>
  );
};

export default ChatGuide;
