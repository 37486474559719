import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./FileViewer.scss";

const FileViewer = ({ fileUrl, fileName, pages, isOpen, onClose }) => {
  const [pdfContent, setPdfContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen && fileUrl) {
      setIsLoading(true);
      fetch(fileUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.arrayBuffer();
        })
        .then((arrayBuffer) => {
          const base64 = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setPdfContent(`data:application/pdf;base64,${base64}`);
          setError(null);
        })
        .catch((error) => {
          console.error("Error fetching PDF:", error);
          setError(`Error loading PDF: ${error.message}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isOpen, fileUrl]);

  if (!isOpen) return null;

  return (
    <>
      {" "}
      {error ? (
        <div className="file-viewer-overlay" onClick={onClose}>
          <div className="error">
            <p>
              {" "}
              🔎 죄송합니다. 지금은 문서를 열 수 없습니다. <br></br>인트라넷
              환경의 PC에서 다시 시도해 주세요.
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="file-viewer-overlay" onClick={onClose}></div>
          <div className="file-viewer">
            <div className="file-viewer-header">
              <div id="file-viewer-title">
                📂 {fileName}
                <br></br>
                <i>pages: {pages.toString()}</i>
              </div>
              <button onClick={onClose}>
                <IoMdClose />
              </button>
            </div>
            <div className="file-viewer-content">
              {isLoading && <div>Loading PDF...</div>}
              {pdfContent && (
                <iframe
                  src={`${pdfContent}#toolbar=0&navpanes=0&scrollbar=0`}
                  title={fileName}
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                />
                // <embed
                //   src={`${fileUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                //   type="application/pdf"
                //   width="100%"
                //   height="100%"
                // />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FileViewer;
