import React, { useState, useEffect, useRef } from "react";
import "./ChatContainer.scss";
import mailIcon from "../../images/mail.png";
import devAssistantIcon from "../../images/dev_x.png";
import { IoSend } from "react-icons/io5";
import { FaStopCircle } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import Feedback from "../Feedback/Feedback";
import MailChatGuide from "../ChatGuide/MailChatGuide";
import GeneratingMessage from "../GeneratingMessage/GeneratingMessage";
import ReactMarkdown from "react-markdown";

const MailChatContainer = ({
  isSidebarOpen,
  isDevMode,
  handleDevMode,
  handleOpenFile,
  currentServer,
  userInfo,
  isRAG,
  handleOpenRAGChat,
  handleOpenMailChat,
}) => {
  const [messages, setMessages] = useState([]);
  const [generateMessage, setGenerateMessage] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const updateMessageRef = useRef("");
  const controllerRef = useRef(null);
  const formRef = useRef(null);
  const fileObjectsRef = useRef([]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [inputMessage]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isGenerating) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: inputMessage, isFeedbackEnabled: false },
    ]);
    setInputMessage("");

    const controller = new AbortController();
    controllerRef.current = controller;

    try {
      const response = await fetch(`${currentServer}/chat_service/email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userInfo.id, content: inputMessage }),
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      setIsGenerating(true);
      updateMessageRef.current = "";
      fileObjectsRef.current = [];
      let buffer = ""; // 스트림 데이터를 임시로 저장할 버퍼
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        // 스트림에서 데이터를 읽고 디코딩
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;

        // 구분자 "\n\n"을 기준으로 데이터를 분리
        let parts = buffer.split("\n\n");

        // 마지막 요소는 완전한 데이터가 아닐 수 있으므로 버퍼에 남겨둠
        buffer = parts.pop();

        // 각 완전한 응답을 처리
        for (const part of parts) {
          try {
            const chatObject = JSON.parse(part);
            // 데이터를 처리하는 로직
            if (chatObject.content) {
              setGenerateMessage((prev) => {
                updateMessageRef.current = prev + chatObject.content;
                return prev + chatObject.content;
              });
            }
            if ("file_name" in chatObject) {
              fileObjectsRef.current.push(chatObject);
            }

            if ("is_feedback_enabled" in chatObject) {
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  role: "assistant",
                  content: updateMessageRef.current,
                  isFeedbackEnabled: chatObject.is_feedback_enabled,
                },
              ]);
              fileObjectsRef.current = [];
            }
          } catch (error) {
            console.error("Failed to parse JSON:", error);
          }
        }
      }
    } catch (err) {
      if (err.name === "AbortError") {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "assistant",
            content: updateMessageRef.current,
            isFeedbackEnabled: false,
          },
        ]);
      } else {
        console.error("스트림 에러:", err);
      }
    } finally {
      setIsGenerating(false);
      setGenerateMessage("");
      controllerRef.current = null;
    }
  };

  const stopGenerating = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      controllerRef.current = null;
      setIsGenerating(false);
    }
  };

  const Message = ({ content, fileViewers }) => {
    return (
      <div>
        <ReactMarkdown>{content}</ReactMarkdown>
        {fileViewers && fileViewers}
      </div>
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  //메일 내용 복사
  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .catch((err) => console.error("Failed to copy text:", err));
  };

  return (
    <div className={`chat-container ${isSidebarOpen ? "sidebar-open" : ""}`}>
      <div className="chat-header">
        <div id="menu">
          <button id="deactive" onClick={handleOpenRAGChat}>
            사규 질답
          </button>
          <button id="active" onClick={handleOpenMailChat}>
            메일 검토
          </button>
        </div>
        <img
          src={isDevMode ? devAssistantIcon : mailIcon}
          alt="Assistant"
          className="assistant-icon"
          onClick={handleDevMode}
        />
      </div>

      <div className="messages-container">
        <MailChatGuide />
        {messages.map((msg, index) => (
          <div key={index} className={`message-wrapper ${msg.role}`}>
            <div className={`message ${msg.role}`}>
              <div className="message-content">
                <Message content={msg.content} fileViewers={msg.fileViewers} />
                {msg.role === "assistant" ? (
                  <button id="copy" onClick={() => copyText(msg.content)}>
                    <MdContentCopy />
                  </button>
                ) : null}
              </div>
            </div>
            {msg.role === "assistant" && msg.isFeedbackEnabled && (
              <Feedback
                question={messages[index - 1].content}
                answer={messages[index].content}
                userInfo={userInfo}
              />
            )}
          </div>
        ))}
        {generateMessage.length > 0 && (
          <div className="message-wrapper assistant">
            <div className="message assistant">
              <div className="message-content generating">
                <GeneratingMessage content={generateMessage} />
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form ref={formRef} className="input-form" onSubmit={handleSubmit}>
        <textarea
          type="text"
          ref={textareaRef}
          value={inputMessage}
          onChange={(e) => {
            setInputMessage(e.target.value);
          }}
          placeholder="메일 내용을 작성해 주세요"
          disabled={isGenerating}
          rows={1}
          onKeyPress={handleKeyPress}
          maxLength="1024"
        />
        {isGenerating ? (
          <button type="button" className="stop" onClick={stopGenerating}>
            <FaStopCircle />
          </button>
        ) : (
          <button type="submit">{<IoSend />}</button>
        )}
      </form>
    </div>
  );
};

export default MailChatContainer;
